import { defineStore } from 'pinia'

export const useRegistrationStore = defineStore('registrationStore', () => {
  const step = ref(0)
  const proSelected = ref(false)
  const company = ref(null)
  const account = ref(null)
  const matchingCompanies = ref([])
  const pendingConfirmation = ref(false) // Need to confirm account via phone
  const pendingValidation = ref(false) // Need to be reviewed by StockPro

  const getStep = computed(() => step.value)
  const getProSelected = computed(() => proSelected.value)
  const getAccount = computed(() => account.value)
  const getMatchingCompanies = computed(() => matchingCompanies.value)
  const getCompany = computed(() => company.value)
  const getPendingConfirmation = computed(() => pendingConfirmation.value)
  const getPendingValidation = computed(() => pendingValidation.value)

  const setStep = value => {
    step.value = value
  }
  const setProSelected = value => {
    proSelected.value = value
  }
  const setCompany = value => {
    company.value = value
  }
  const setMatchingCompanies = value => {
    matchingCompanies.value = value
  }
  const setPendingConfirmation = value => {
    pendingConfirmation.value = value
  }
  const setPendingValidation = value => {
    pendingValidation.value = value
  }

  return {
    step,
    proSelected,
    company,
    account,
    matchingCompanies,
    pendingConfirmation,
    pendingValidation,
    getStep,
    getProSelected,
    getAccount,
    getCompany,
    getMatchingCompanies,
    getPendingConfirmation,
    getPendingValidation,
    setStep,
    setProSelected,
    setCompany,
    setMatchingCompanies,
    setPendingConfirmation,
    setPendingValidation,
  }
})
